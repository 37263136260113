import * as React from 'react';
import {ISantaProps} from '@wix/native-components-infra/dist/es/src/types/types';
import {IPropsInjectedByViewerScript} from '../types/app-types';

const PropsContext = React.createContext<IProvidedGlobalProps>(undefined);

export interface IProvidedGlobalProps {
  globals?: GlobalProps;
}

export type GlobalProps = Pick<
  IPropsInjectedByViewerScript & ISantaProps,
  | 'appLoadBI'
  | 'isSEO'
  | 'texts'
  | 'style'
  | 'product'
  | 'isInteractive'
  | 'handleAddToCart'
  | 'navigate'
  | 'dimensions'
  | 'productPageUrl'
  | 'widgetPreset'
  | 'isMobile'
  | 'horizontalLayout'
  | 'experiments'
>;

export function withGlobalPropsProvider(Component) {
  return (props: IPropsInjectedByViewerScript & ISantaProps) => {
    const {
      texts,
      isSEO,
      product,
      style,
      isInteractive,
      handleAddToCart,
      navigate,
      productPageUrl,
      widgetPreset,
      isMobile,
      horizontalLayout,
      experiments,
      ...passThroughProps
    } = props;
    const {dimensions, appLoadBI} = (props as any).host;
    return (
      <PropsContext.Provider
        value={{
          globals: {
            appLoadBI,
            texts,
            isSEO,
            style,
            product,
            isInteractive,
            handleAddToCart,
            navigate,
            dimensions,
            productPageUrl,
            widgetPreset,
            isMobile,
            horizontalLayout,
            experiments,
          },
        }}>
        <Component {...passThroughProps} />
      </PropsContext.Provider>
    );
  };
}

export function withGlobalProps(Component: any): React.FunctionComponent | any {
  return props => {
    return <PropsContext.Consumer>{globalProps => <Component {...globalProps} {...props} />}</PropsContext.Consumer>;
  };
}
