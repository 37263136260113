import * as React from 'react';
import classnames from 'classnames';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobalProps} from '../../providers/GlobalPropsProvider';
import {IProductPrice} from '../../types/app-types';

import s from './ProductPrice.scss';

export interface IProductPriceProps extends IProvidedTranslationProps, IProductPrice {}

export enum DataHook {
  Root = 'product-price',
  FormattedPrice = 'formatted-primary-price',
}

@withGlobalProps
@withTranslations('globals.texts')
export class ProductPrice extends React.Component<IProductPriceProps> {
  private renderPrimary() {
    const {formattedComparePrice, formattedPrice, comparePrice, t} = this.props;
    return (
      <>
        <span data-hook={DataHook.FormattedPrice}>{comparePrice > 0 ? formattedComparePrice : formattedPrice}</span>
        <span className="sr-only" data-hook="sr-formatted-primary-price">
          {t(comparePrice ? 'sr.PRODUCT_PRICE_AFTER_DISCOUNT' : 'sr.PRODUCT_PRICE_WHEN_THERE_IS_NO_DISCOUNT')}
        </span>
      </>
    );
  }

  private renderSecondary() {
    const {formattedPrice, t} = this.props;

    return (
      <>
        <span data-hook="formatted-secondary-price" className={classnames(s.secondary, s.crossed)}>
          <span>{formattedPrice}</span>
        </span>
        <span className="sr-only" data-hook="sr-formatted-secondary-price">
          {t('sr.PRODUCT_PRICE_BEFORE_DISCOUNT')}
        </span>
      </>
    );
  }

  public render() {
    const {price, comparePrice} = this.props;
    if (!price) {
      return null;
    }
    return (
      <div data-hook={DataHook.Root} className={s.productPrice}>
        {comparePrice > 0 && this.renderSecondary()}
        {this.renderPrimary()}
      </div>
    );
  }
}
