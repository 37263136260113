import * as React from 'react';
import classnames from 'classnames';
import {ProductName} from '../ProductName/ProductName';
import {Divider} from '../Divider/Divider';
import {ProductPrice} from '../ProductPrice/ProductPrice';
import {OutOfStock} from '../OutOfStock/OutOfStock';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/GlobalPropsProvider';
import {withTranslations} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {IProvidedTranslationProps} from '@wix/wixstores-client-common-components/dist/src/outOfIframes/translations';
import {getProductWidgetSettingsFromProps, WidgetAlignmentEnum} from '../../commons/styleParamsService';
import s from './ProductDetails.scss';

export interface IProductDetailsProps extends IProvidedGlobalProps, IProvidedTranslationProps {
  hideOnHover?: boolean;
}

export enum DataHook {
  Root = 'product-details',
}

@withGlobalProps
@withTranslations('globals.texts')
export class ProductDetails extends React.Component<IProductDetailsProps> {
  public static defaultProps: Partial<IProductDetailsProps> = {
    hideOnHover: false,
  };

  public render() {
    const {
      globals: {product},
      hideOnHover,
    } = this.props;
    const settings = getProductWidgetSettingsFromProps(this.props);
    return (
      <div
        data-hook={DataHook.Root}
        className={classnames(s.productDetails, this.getAlignmentClass(settings.alignment), {
          [s.productDetailsHideOnHover]: hideOnHover,
        })}>
        {settings.titleToggle ? (
          <ProductName name={product.name} bottomGutter={settings.priceToggle || settings.separatorToggle} />
        ) : null}
        {settings.separatorToggle && <Divider bottomGutter={settings.priceToggle} />}
        {product.isInStock && settings.priceToggle && (
          <ProductPrice
            price={product.price}
            formattedPrice={product.formattedPrice}
            comparePrice={product.comparePrice}
            formattedComparePrice={product.formattedComparePrice}
          />
        )}
        {!product.isInStock && settings.priceToggle ? <OutOfStock /> : null}
      </div>
    );
  }

  private getAlignmentClass(alignment: WidgetAlignmentEnum): string {
    switch (alignment) {
      case WidgetAlignmentEnum.LEFT:
        return s.leftText;
      case WidgetAlignmentEnum.RIGHT:
        return s.rightText;
      case WidgetAlignmentEnum.CENTER:
      default:
        return s.centerText;
    }
  }
}
