import _ from 'lodash';
import {FormFactor} from '../constants';
import {getStyleParamsWithDefaults} from '@wix/wixstores-client-common-components/dist/src/outOfIframes/defaultStyleParams/getStyleParamsWithDefaults';
import {IWixStyleParams} from '@wix/wixstores-client-core/dist/src/types/wix-sdk';
import {IWixWindowFormFactor} from '@wix/native-components-infra/dist/src/types/types';
import {IProductWidgetSettings} from '../types/app-types';

const isMobile = (formFactor: string) => formFactor === FormFactor.Mobile;

export enum WidgetAreaEnum {
  CONTENT = 'content',
  VISUAL = 'visual',
  ALL = 'all',
}

export enum WidgetActionEnum {
  NAVIGATE = 'navigate',
  ADDTOCART = 'addToCart',
}

export enum ImageScaling {
  CROP = 'crop',
  FIT = 'fit',
}

export enum WidgetDirectionEnum {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum WidgetAlignmentEnum {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum WidgetPresetIdEnum {
  VERTICAL = 'Wix_Store_Product_Widget_Vertical',
  HORIZONTAL = 'Wix_Store_Product_Widget_Horizontal',
}

export const createTextParam = (value: string) => ({
  value,
  fontStyleParam: false,
});

export interface IRequiredSettingsForDefaultStyleParams {
  isMobile: boolean;
  orientation: WidgetPresetIdEnum;
}

export const getDefaultStyleParams = (settings: IRequiredSettingsForDefaultStyleParams): Partial<IWixStyleParams> => ({
  booleans: {
    titleToggle: true,
    priceToggle: true,
    separatorToggle: settings.orientation === WidgetPresetIdEnum.HORIZONTAL,
    extendOnHoverToggle: true,
    extendOnHolidayToggle: false,
    extendFullImageOnHoverToggle: false,
    hoverButtonToggle: !settings.isMobile && settings.orientation === WidgetPresetIdEnum.VERTICAL,
    widgetButtonToggle: !(settings.isMobile && settings.orientation === WidgetPresetIdEnum.VERTICAL),
  },
  numbers: {
    hoverBackgroundColorOpacity: 0,
    hoverBorderSize: 0,
    actionButton_borderSize: 0,
    actionButton_cornersRadius: 0,
  },
  fonts: {
    imageScaling: createTextParam(ImageScaling.CROP),
    widgetAction: createTextParam(WidgetActionEnum.NAVIGATE),
    layout: createTextParam(''),
    alignment: createTextParam(
      settings.orientation === WidgetPresetIdEnum.HORIZONTAL ? WidgetAlignmentEnum.CENTER : WidgetAlignmentEnum.LEFT
    ),
    widgetDirection: createTextParam(WidgetDirectionEnum.LEFT),
    hoverArea: createTextParam(WidgetAreaEnum.CONTENT),
    hoverBorderArea: createTextParam(WidgetAreaEnum.CONTENT),
    hoverButtonArea: createTextParam(WidgetAreaEnum.CONTENT),
    visualWidth: createTextParam('50%'),
    hoverState: createTextParam('zoom'),
  },
});

export function getRuntimeStyleParams(
  styleParams: Partial<IWixStyleParams>,
  {formFactor, widgetPreset}: {formFactor: FormFactor | IWixWindowFormFactor; widgetPreset: WidgetPresetIdEnum}
) {
  const result = getStyleParamsWithDefaults(styleParams as IWixStyleParams, () => ({
    defaults: getDefaultStyleParams({isMobile: isMobile(formFactor), orientation: widgetPreset}),
  }));

  let mobile = {};
  if (isMobile(formFactor)) {
    mobile = {
      // booleans: {
      //   widgetButtonToggle: false,
      // },
    };
  }
  return _.merge({}, result, mobile);
}

const textKeys = [
  'alignment',
  'widgetAction',
  'imageScaling',
  'widgetDirection',
  'hoverArea',
  'hoverBorderArea',
  'hoverButtonArea',
  'hoverState',
];

const extractParams = (styleParams: IWixStyleParams): {fonts: Record<string, any>} => {
  const fonts = {};
  textKeys.forEach(key => {
    if (styleParams.fonts[key]) {
      fonts[key] = styleParams.fonts[key].value;
    }
  });
  return {
    fonts,
  };
};

export function getProductWidgetSettings(styleParams: IWixStyleParams): IProductWidgetSettings {
  const {
    numbers: {hoverBackgroundColorOpacity, hoverBorderSize, actionButton_cornersRadius},
    booleans: {
      widgetButtonToggle,
      titleToggle,
      full_width: fullWidth,
      priceToggle,
      separatorToggle,
      hoverButtonToggle,
      extendOnHoverToggle,
      extendOnHolidayToggle,
      extendFullImageOnHoverToggle,
    },
  } = styleParams;
  const {fonts: fontParams} = extractParams(styleParams);

  return {
    ...(fontParams as any),
    fullWidth,
    hoverBackgroundColorOpacity,
    hoverBorderSize,
    actionButton_cornersRadius,
    widgetButtonToggle,
    titleToggle,
    priceToggle,
    separatorToggle,
    hoverButtonToggle,
    extendOnHoverToggle,
    extendOnHolidayToggle,
    extendFullImageOnHoverToggle,
  };
}

export function getProductWidgetSettingsFromProps(props: any): IProductWidgetSettings {
  return getProductWidgetSettings(props.globals.style.styleParams);
}
