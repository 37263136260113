import * as React from 'react';
import classnames from 'classnames';
import s from './Divider.scss';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/GlobalPropsProvider';

export interface IDividerProps extends IProvidedGlobalProps {
  bottomGutter?: boolean;
}

export enum DataHook {
  Root = 'product-widget-divider',
}

@withGlobalProps
export class Divider extends React.Component<IDividerProps> {
  public static defaultProps: Partial<IDividerProps> = {
    bottomGutter: false,
  };

  public render() {
    const {bottomGutter} = this.props;
    const classes = classnames(s.divider, {[s.bottomGutter]: bottomGutter});
    return <div className={classes} data-hook={DataHook.Root} />;
  }
}
