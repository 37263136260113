export const translationPath = (baseUrl, locale = 'en') =>
  `${baseUrl}assets/locale/productWidget/productWidget_${locale}.json`;
export const widgetId = '13ec3e79-e668-cc0c-2d48-e99d53a213dd';

export const enum FormFactor {
  Desktop = 'Desktop',
  Mobile = 'Mobile',
  Tablet = 'Tablet',
}

export const MULTILINGUAL_TO_TRANSLATIONS_MAP = {
  NAVIGATE_TO_PRODUCT_PAGE_BUTTON: 'PRODUCT_WIDGET_BUTTON_TEXT',
  ADD_TO_CART_BUTTON: 'PRODUCT_WIDGET_BUTTON_TEXT',
};

export const PRODUCT_WIDGET_APP_NAME = 'wixstores-product-widget';
