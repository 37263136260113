import * as React from 'react';
import {getProductWidgetSettingsFromProps, WidgetDirectionEnum, WidgetAreaEnum} from '../../commons/styleParamsService';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import classnames from 'classnames';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/GlobalPropsProvider';
import {ContentArea} from '../ContentArea/ContentArea';
import {MediaArea} from '../MediaArea/MediaArea';
import {FlexDirectionProperty} from 'csstype';
import s from './ProductWidgetLayout.scss';
import borderAreaStyle from './borderArea.scss';

export enum DataHook {
  Root = 'product-widget-layout',
}

export interface IProductWidgetLayoutProps extends IProvidedTranslationProps, IProvidedGlobalProps {}

@withGlobalProps
@withTranslations('globals.texts')
export class ProductWidgetLayout extends React.Component<IProductWidgetLayoutProps> {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  private handleClick(event: React.MouseEvent<HTMLAnchorElement>) {
    const {
      globals: {navigate},
    } = this.props;

    event.preventDefault();
    navigate && navigate();
  }

  public render() {
    const {
      globals: {productPageUrl, horizontalLayout},
    } = this.props;
    const settings = getProductWidgetSettingsFromProps(this.props);
    let direction: FlexDirectionProperty;
    if (!horizontalLayout) {
      direction = 'column';
    } else if (settings.widgetDirection === WidgetDirectionEnum.LEFT) {
      direction = 'row';
    } else {
      direction = 'row-reverse';
    }

    return (
      <a
        data-hook={DataHook.Root}
        href={productPageUrl}
        onClick={this.handleClick}
        style={{flexDirection: direction}}
        className={classnames(s.productWidgetLayout, {
          [borderAreaStyle.borderAreaHover]: settings.hoverBorderArea === WidgetAreaEnum.ALL,
        })}>
        <MediaArea />
        <ContentArea />
      </a>
    );
  }
}
