import * as React from 'react';
import classnames from 'classnames';
import {IPropsInjectedByViewerScript} from '../../types/app-types';
import s from './ProductWidgetApp.scss';
import {ProductWidgetLayout} from '../ProductWidgetLayout/ProductWidgetLayout';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/GlobalPropsProvider';
import '../../styles/app.global.scss';

export type IProductWidgetAppProps = IPropsInjectedByViewerScript & IProvidedGlobalProps;

export enum DataHook {
  Root = 'product-widget',
}

@withGlobalProps
export class ProductWidgetApp extends React.Component<IProductWidgetAppProps> {
  private isReported: boolean = false;

  public componentDidMount() {
    if (this.props.globals.isInteractive) {
      this.reportAppLoaded();
    }
  }

  public componentWillReceiveProps(props) {
    if (!this.props.globals.isInteractive && props.globals.isInteractive) {
      this.reportAppLoaded();
    }
  }

  private reportAppLoaded() {
    if (!this.isReported) {
      this.props.globals.appLoadBI.loaded();
      this.isReported = true;
    }

    this.safeRun(this.props.onAppLoaded);
  }

  private safeRun(fn) {
    if (typeof fn === 'function') {
      fn();
    }
  }

  public render() {
    const {
      horizontalLayout,
      dimensions: {height, width},
    } = this.props.globals;

    return (
      <section
        style={{width, height}}
        data-hook={DataHook.Root}
        className={classnames(s.productWidgetApp, {
          [s.horizontal]: horizontalLayout,
        })}>
        <ProductWidgetLayout />
      </section>
    );
  }
}
