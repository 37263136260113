import * as React from 'react';
import {IProvidedGlobalProps, withGlobalProps} from '../../providers/GlobalPropsProvider';
import {ProductImage} from './ProductImage/ProductImage';
import {getProductWidgetSettingsFromProps, ImageScaling} from '../../commons/styleParamsService';
import {ImageModeValues} from '@wix/wixstores-client-core/dist/es/src/media/constants';
import {EmptyProductImage} from './EmptyProductImage/EmptyProductImage';

export type IMediaAreaProps = IProvidedGlobalProps;

interface IMediaAreaState {
  mountedToDOM: boolean;
}

@withGlobalProps
export class MediaArea extends React.Component<IMediaAreaProps, IMediaAreaState> {
  public state: IMediaAreaState = {
    mountedToDOM: false,
  };

  public componentDidMount() {
    this.setState({
      mountedToDOM: true,
    });
  }

  public render() {
    const {
      globals: {product, horizontalLayout},
    } = this.props;
    const {mountedToDOM} = this.state;
    const settings = getProductWidgetSettingsFromProps(this.props);
    const imageMode = settings.imageScaling === ImageScaling.FIT ? ImageModeValues.FIT : ImageModeValues.CROP;
    return (
      <>
        {product.media && product.media[0] ? (
          <ProductImage
            media={product.media[0]}
            productName={product.name}
            imageMode={imageMode}
            mountedToDOM={mountedToDOM}
          />
        ) : (
          <EmptyProductImage horizontalLayout={horizontalLayout} extendOnHoverToggle={settings.extendOnHoverToggle} />
        )}
      </>
    );
  }
}
